import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import { extraDriverClear, extraDriverGetByBooking } from '@crac/core/redux/actions/ExtraDriverActions';
import type { IExtraDriverReducerState } from '@crac/core/redux-store/reducersState/extraDriver';

const initialState: IExtraDriverReducerState = {
	extraDriver: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriverRemoveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriverSave: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriverPrevisionServices: {
		inProgress: false,
		messages: [],
		ok: true,
		data: {
			servicesToAdd: [],
			servicesToRemove: [],
		},
	},
	extraDrivers: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};
const extraDriverSlice = createSlice({
	name: 'ExtraDriverSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(extraDriverGetByBooking.pending, (state) => {
				return {
					...state,
					extraDrivers: {
						data: state.extraDrivers.data,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					extraDrivers: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetByBooking.rejected, (state, action) => {
				return {
					...state,
					extraDrivers: {
						data: state.extraDrivers.data,
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// CLEAR EXTRA DRIVER & CLEAR BOOKING DETAIL
		builder.addMatcher(isAnyOf(extraDriverClear, bookingDetailClear), () => {
			return initialState;
		});
	},
});

export const extraDriverReducer = extraDriverSlice.reducer;
